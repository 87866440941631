export enum Route {
  ANNUAL_REPORT = '/annual-report',
  ARTICLE = '/article',
  CONTACT = '/contact',
  EVENTS = '/events',
  FEATURE = '/features',
  FINANCIALS = '/financials',
  GRANT_DETAILS = '/grant-details',
  GRANT_MAKING_AREAS = '/grant-programs',
  GRANT_RESOURCES = '/resources',
  GRANT_SEARCH = '/grant-database',
  GRANT_STORY = '/grant-story',
  HISTORY = '/history',
  HOME = '/',
  IDEA = '/ideas',
  MISSION = '/mission',
  MMUF = '/mmuf',
  NEWS = '/news',
  NEWSLETTER = '/newsletter',
  PEOPLE = '/people',
  PRESS_ROOM = '/press',
  PRIVACY_POLICY = '/privacy-policy',
  PRIVACY_POLICY_ES = '/privacy-policy/es',
  REPORT = '/report',
  SEARCH = '/search',
  TERMS_OF_USE = '/terms',
  TERMS_OF_USE_ES = '/terms/es',
  TRUSTEES = '/people/trustees',
  VOICES = '/voices',
}

export const staticRoutes = [
  Route.ANNUAL_REPORT,
  Route.CONTACT,
  Route.EVENTS,
  Route.FINANCIALS,
  Route.GRANT_MAKING_AREAS,
  Route.GRANT_RESOURCES,
  Route.GRANT_SEARCH,
  Route.HISTORY,
  Route.HOME,
  Route.MISSION,
  Route.MMUF,
  Route.NEWS,
  Route.NEWSLETTER,
  Route.PEOPLE,
  Route.PRESS_ROOM,
  Route.PRIVACY_POLICY,
  Route.PRIVACY_POLICY_ES,
  Route.SEARCH,
  Route.TERMS_OF_USE,
  Route.TERMS_OF_USE_ES,
  Route.TRUSTEES,
] as const;
