import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { breadcrumbLabels, hasLabel } from './config';
import { IS_HOMEPAGE, useBreadcrumbStack } from './useBreadcrumbStack';

/**
 * This component only exists so the breadcrumb stack can be lazy-loaded.
 */
export function Stack() {
  const { entries, labels, current, setCurrent, createNewStack, getEntryIndex } =
    useBreadcrumbStack();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!pathname || labels.at(current.at(-1) ?? 0)?.path === pathname) {
      return;
    }

    const cacheKey = window.history.state?.key;
    if (!cacheKey) {
      return;
    }

    // if the current browser cache key is in the stack, the user pressed the back or forward button
    // we just need to set the current stack entry to the existing record
    if (cacheKey in entries) {
      setCurrent(cacheKey);
      return;
    }

    // when the user clicks a breadcrumb, the "pop" query parameter is set to "true"
    // we want to create a new stack entry with everything from "current" except the top item
    if (searchParams?.get('pop') === 'true') {
      createNewStack(cacheKey, current.slice(0, -1));
      return;
    }

    // if all else fails, we just create a new stack extending "current" under the new cacheKey
    const label = hasLabel(pathname)
      ? breadcrumbLabels[pathname]
      : document.title.replace(' | Mellon Foundation', '');
    const entryIndex = getEntryIndex({
      path: pathname,
      label,
    });
    if (entryIndex === IS_HOMEPAGE) {
      setCurrent(null);
      return;
    }
    const stack = searchParams?.get('follow') === 'false' ? [entryIndex] : [...current, entryIndex];
    createNewStack(cacheKey, stack);
  }, [createNewStack, current, entries, getEntryIndex, labels, pathname, searchParams, setCurrent]);

  return null;
}
