import { Route } from '~/v1/constants/route';

/**
 * Represents custom breadcrumb labels for routes. By default, breadcrumbs use the page title.
 *
 * You'll probably only need this for pages with odd titles (e.g. "Watch Events Hosted by the Mellon Foundation").
 */
export const breadcrumbLabels = {
  [Route.MISSION]: 'Mission',
  [Route.HISTORY]: 'History',
  [Route.GRANT_MAKING_AREAS]: 'Grantmaking Areas',
  [Route.PEOPLE]: 'People',
  [Route.FINANCIALS]: 'Financials',
  [Route.NEWS]: 'News',
  [Route.EVENTS]: 'Events',
} as const;

export function hasLabel(path: string): path is keyof typeof breadcrumbLabels {
  return path in breadcrumbLabels;
}
